import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

const HomeRoutes = {
  layout: HomeLayout,
  path: "*",
  routes: [
    {
      exact: true,
      path: "/contact",
      component: lazy(() => import("src/views/home/ContactView")),
    },
    {
      exact: true,
      path: "/",
      component: lazy(() => import("src/views/home/LandingPageView")),
    },
    {
      exact: true,
      path: "/career",
      component: lazy(() => import("src/views/home/CareerView")),
    },
    {
      exact: true,
      path: "/labelease",
      component: lazy(() => import("src/views/home/Labelease")),
    },
    {
      exact: true,
      path: "/DeliveryApproach",
      component: lazy(() => import("src/views/home/DeliveryApproach")),
    },
    {
      exact: true,
      path: "/robot",
      component: lazy(() => import("src/views/home/Robot")),
    },
    {
      exact: true,
      path: "/components",
      component: lazy(() => import("src/views/home/ComponentsView")),
    },
    {
      exact: true,
      path: "/sidebar",
      component: lazy(() => import("src/layouts/HomeLayout/Sidebar")),
    },
    {
      exact: true,
      path: "/mendix",
      component: lazy(() => import("src/views/home/MendixView")),
    },
    {
      exact: true,
      path: "/case-studies/:caseStudyId",
      component: lazy(() => import("src/views/home/Case-studies/Casestudies")),
    },
     {
      exact: true,
      path: "/case-studies/:caseStudyId",
      component: lazy(() => import("src/views/home/Case-studies/Casestudies")),
    },

    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default HomeRoutes;
